import { template as template_652b84380ed74382a5ec292f5988568f } from "@ember/template-compiler";
const FKText = template_652b84380ed74382a5ec292f5988568f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
