import { template as template_fe5edc34f5c746e0a191879bd21cfa2a } from "@ember/template-compiler";
const FKLabel = template_fe5edc34f5c746e0a191879bd21cfa2a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
