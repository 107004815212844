import { template as template_073e2bd026eb4c2e8c6bceb6d61adb45 } from "@ember/template-compiler";
const WelcomeHeader = template_073e2bd026eb4c2e8c6bceb6d61adb45(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
