import { template as template_e128caa6d1924918bc2e29ef335d0c19 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e128caa6d1924918bc2e29ef335d0c19(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
