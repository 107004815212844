import { template as template_db5dd53e7acf4ed98c1fbee569f73651 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_db5dd53e7acf4ed98c1fbee569f73651(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_db5dd53e7acf4ed98c1fbee569f73651(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_db5dd53e7acf4ed98c1fbee569f73651(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
